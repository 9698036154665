<template>
     <div class="wrapper">
          <div class="box">
               <img src="https://static.yihu365.cn/img/h5Img/assets/img/banner.59532e3f.png" alt="">
               <div class="btn" @click="goUrl">
<!--                    <img src="https://static.yihu365.cn/img/h5Img/assets/img/btn-1.002445bb.png" alt="">-->
                     点此注册
               </div>
          </div>
          <div class="bottom">
               <ul>
                    <li>
                         医护到家是一家医生护士上门服务平台，是政府批准的互联网医养服务试点。通过医护到家，可以随时预约周边护士，提供上门护理服务。
                    </li>
                    <li>
                         <h3>• 30余项服务，全面满足需求：</h3>
                         <p>打针、孕妇护理、换药（含PICC）、吸痰、导尿、鼻饲、灌肠、雾化治疗、外科插线、压疮护理、造口护理、产后护理、新生儿护理、护士催乳等；</p>
                    </li>
                    <li>
                         <h3>• 超8万名医院在职护士：</h3>
                         <p>严格的护士准入机制，正规三甲医院护士，国家卫计委认证，随时为您提供上门护理服务；</p>
                    </li>
                    <li>
                         <h3>• 330个城市，全国范围服务：</h3>
                         <p>服务覆盖北京、上海、深圳、广州、南京、杭州等全国31个省份，330个城市，为全国用户提供到家服务；</p>
                    </li>
                    <li>本活动最终解释权归医护到家所有 </li>
               </ul>
          </div>
     </div>
</template>
<script>
     import {useRouter, useRoute} from 'vue-router'
    export default {
        name: "one",
         setup () {

              let router = useRouter()
              const route = useRoute()
              const goUrl =()=>{
                   router.push({
                        name: 'two',
                        query: {
                             userId:route.query.userId,
                        }
                   })
              }
             return {
                  goUrl
             }
         }
    }
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
   .wrapper{
        background: #6f3bf3;
        .box{
             margin:0;
             position: relative;
             .btn{
                  background: #ffe19e;
                  position: absolute;
                  width:80%;
                  left:60px;
                  bottom:0;
                  z-index: 12;
                  line-height: 70px;
                  border-radius: 30px;
                  text-align: center;
                  font-weight: 600;
                  color:#fff;
                  font-size: 30px;
             }
        }
        .box img{
             width: 100%;
        }
        .bottom{
             background: #6f3bf3;
             padding:40px;
             ul{
                  border-radius: 10px;
                  padding:30px;
                  box-sizing: border-box;
                  background-color: #fff;
                  font-size: 26px;
                  color:#853800;
             }
        }
   }
</style>
